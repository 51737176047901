<template>
  <div class="MicrositeHR__container transparentHeader">
    <MicrositePageHeader :config="headerConfig" />
    <div class="MicrositeHR__contentGrid MicrositeHR__firstGrid">
      <VideoImage :config="videoImage" />
    </div>
    <div class="MicrositeHR__contentGrid">
      <CornerstoneJobsList :config="jobsListConfig" />
    </div>
    <Footer />
  </div>
</template>

<script setup>
import MicrositePageHeader from '../MicrositePageHeader/MicrositePageHeader.vue'
import VideoImage from '../VideoImage/VideoImage.vue'
import CornerstoneJobsList from '../CornerstoneJobsList/CornerstoneJobsList.vue'
import Footer from '../Footer/Footer.vue'

defineProps({
  videoImage: {
    type: Object,
    default: undefined
  },
  jobsListConfig: {
    type: Object,
    default: undefined
  }
})

const headerConfig = {
  metaNavLink: 'iiot.WIKA',
  metaNavLinkText: 'WIKA HR',
  homeLink: '#',
  isMicrositeHR: true,
  langSwitcherOptions: [
    {
      title: 'Deutsch',
      img: 'demo/de_small.png',
      url: 'http://www.google.de'
    },
    {
      title: 'English',
      img: 'demo/en_small.png',
      url: 'http://www.google.com'
    }
  ],
  quickLinks: [
    {
      title: 'WIKA website',
      url: 'http://blog.wika.de/',
      newWindow: 'true',
      icon: 'fa-globe'
    }
  ],
  primaryNav: [
    {
      title: 'KPIs',
      id: '1',
      url: '#kpis'
    },
    {
      title: 'HR Teaser',
      id: '2',
      url: '#hrteaser'
    },
    {
      title: 'Rotating Carousel',
      id: '3',
      url: '#carousel'
    },
    {
      title: 'Locations',
      id: '4',
      url: '#locations',
      subNav: [
        { id: '1', title: 'WIKA Gruppe', url: 'http://www.google.com' },
        { id: '2', title: 'WIKA Deutschland', url: '#' },
        { id: '3', title: 'Neuheiten & Messen', url: '#' }
      ]
    }
  ]
}
</script>

import { createApp } from 'vue'
import App from './App.vue'
import VueTippy from 'vue-tippy'

import initScrollNavigation from '../ScrollNavigation/main'
import initFlipCard from '../FlipCard/main'
import initHeroSlider from '../HeroSlider/main'
import initContactForm from '../ContactForm/main'
import initButton from '../Button/main'
import initVideoImage from '../VideoImage/main'
import initKPIS from '../KPIS/main'
import initRotatingCarousel from '../RotatingCarousel/main'
import initHRTeaser from '../HRTeaser/main'
import initContactList from '../ContactList/main'
import initMicrositeHRJobDetails from '../MicrositeHRJobDetails/main'
import initCornerstoneJobsList from '../CornerstoneJobsList/main'
import initSearchHighlighter from '../SearchHighlighter/main'

import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

const init = () => {
  const app = createApp(App)

  app.use(VueTippy)

  app.mount('#app')

  initScrollNavigation()
  initFlipCard()
  initHeroSlider()
  initContactForm()
  initButton()
  initVideoImage()
  initKPIS()
  initRotatingCarousel()
  initHRTeaser()
  initContactList()
  initMicrositeHRJobDetails()
  initCornerstoneJobsList()
  initSearchHighlighter()
}

if (document.body.classList.contains('cookiebannerEnabled')) {
  window.addEventListener('CookiebotOnLoad', init)
} else {
  init()
}

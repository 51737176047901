<template>
  <div class="Wysiwyg__container">
    <h1 class="Wysiwyg__h1">H1 Element</h1>
    <h2 class="Wysiwyg__h2">H2 Element</h2>
    <h3 class="Wysiwyg__h3">H3 Element</h3>
    <h4 class="Wysiwyg__h4">H4 Element</h4>
    <h5 class="Wysiwyg__h5">H5 Element</h5>
    <h6 class="Wysiwyg__h6">H6 Element</h6>
    <p>
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Hic, accusantium laboriosam ut
      numquam quis rerum nam tempora maiores perspiciatis natus commodi est ipsam. Debitis provident
      nemo delectus ipsa totam minima.
    </p>
    <ul>
      <li>Unordered List</li>
      <li>Pumpenüberwachung und -steuerung</li>
      <li>Filterüberwachung</li>
      <li>Füllstandsmessung an geschlossenen Behältern</li>
    </ul>
    <hr />
    <ol>
      <li>Ordered List</li>
      <li>Pumpenüberwachung und -steuerung</li>
      <li>Filterüberwachung</li>
      <li>Füllstandsmessung an geschlossenen Behältern</li>
    </ol>
    <hr />
    <table>
      <thead>
        <th colspan="4">Table</th>
      </thead>
      <tbody>
        <tr>
          <td>Cell 1</td>
          <td>Cell 2</td>
          <td>Cell 3</td>
          <td>Cell 4</td>
        </tr>
        <tr>
          <td>Cell 5</td>
          <td>Cell 6</td>
          <td>Cell 7</td>
          <td>Cell 8</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

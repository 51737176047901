<template>
  <div :class="$style.container">
    <MicrositePageHeader :config="headerConfig" />
    <MicrositePlaceholder :config="placeholder1" />
    <MicrositePlaceholder :config="placeholder2" />
    <MicrositePlaceholder :config="placeholder3" />
    <MicrositePlaceholder :config="placeholder4" />
    <MicrositePlaceholder :config="placeholder5" />
    <MicrositePlaceholder :config="placeholder6" />
    <Footer />
  </div>
</template>

<script setup>
import Footer from '../Footer/Footer.vue'
import MicrositePageHeader from '../MicrositePageHeader/MicrositePageHeader.vue'
import MicrositePlaceholder from '../MicrositePlaceholder/MicrositePlaceholder.vue'

defineProps({
  placeholder1: {
    default: undefined
  },
  placeholder2: {
    default: undefined
  },
  placeholder3: {
    default: undefined
  },
  placeholder4: {
    default: undefined
  },
  placeholder5: {
    default: undefined
  },
  placeholder6: {
    default: undefined
  }
})

const headerConfig = {
  metaNavLink: 'iiot.WIKA',
  metaNavLinkText: 'WIKA IIoT solutions',
  homeLink: '#',
  langSwitcherOptions: [
    {
      title: 'Deutsch',
      img: 'demo/de_small.png',
      url: 'http://www.google.de'
    },
    {
      title: 'English',
      img: 'demo/en_small.png',
      url: 'http://www.google.com'
    }
  ],
  quickLinks: [
    {
      title: 'WIKA website',
      url: 'http://blog.wika.de/',
      newWindow: 'true',
      icon: 'fa-globe'
    }
  ],
  primaryNav: [
    {
      title: 'Text Image Element',
      id: '1',
      url: '#placeholder2'
    },
    {
      title: 'Content Row',
      id: '2',
      url: '#placeholder3',
      isActive: true
    },
    {
      title: 'Hero Slider',
      id: '3',
      url: '#placeholder4'
    },
    {
      title: 'Scroll Navigation',
      id: '4',
      url: '#placeholder5'
    },
    {
      title: 'WYSIWYG',
      id: '5',
      url: '#placeholder6'
    }
  ]
}
</script>

<style module lang="scss">
@import './Microsite.scss';
</style>

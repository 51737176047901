<template>
  <div :class="[$style.container, 'transparentHeader']">
    <MicrositePageHeader :config="headerConfig" />
    <div :class="[$style.contentGrid, $style.firstGrid]">
      <VideoImage :config="videoImage" />
    </div>
    <div :class="$style.contentGrid">
      <div id="kpis" :class="$style.anchor" />
      <KPIS :config="kpis" />
    </div>
    <div :class="$style.contentGrid">
      <div id="hrteaser" :class="$style.anchor" />
      <HRTeaser :config="hrTeaser" />
    </div>
    <div :class="$style.contentGrid">
      <div id="carousel" :class="$style.anchor" />
      <RotatingCarousel
        v-if="slides"
        uid="hrCarousel"
        :slides="slides"
        :autoplay="true"
        bg-img-url="//placehold.it/1800x750/"
      />
    </div>
    <div :class="$style.contentGrid">
      <div id="contactList" :class="$style.anchor" />
      <slot name="tabsConfig" />
      <ContactList :config="listConfig" />
    </div>
    <Footer />
  </div>
</template>

<script setup>
import Footer from '../Footer/Footer.vue'
import RotatingCarousel from '../RotatingCarousel/RotatingCarousel.vue'
import VideoImage from '../VideoImage/VideoImage.vue'
import KPIS from '../KPIS/KPIS.vue'
import MicrositePageHeader from '../MicrositePageHeader/MicrositePageHeader.vue'
import HRTeaser from '../HRTeaser/HRTeaser.vue'
import ContactList from '../ContactList/ContactList.vue'

defineProps({
  videoImage: {
    default: undefined
  },
  slides: {
    default: undefined
  },
  kpis: {
    default: undefined
  },
  hrTeaser: {
    default: undefined
  },
  listConfig: {
    default: undefined
  }
})

const headerConfig = {
  metaNavLink: 'iiot.WIKA',
  metaNavLinkText: 'WIKA HR',
  homeLink: '#',
  isMicrositeHR: true,
  langSwitcherOptions: [
    {
      title: 'Deutsch',
      img: 'demo/de_small.png',
      url: 'http://www.google.de'
    },
    {
      title: 'English',
      img: 'demo/en_small.png',
      url: 'http://www.google.com'
    }
  ],
  quickLinks: [
    {
      title: 'WIKA website',
      url: 'http://blog.wika.de/',
      newWindow: 'true',
      icon: 'fa-globe'
    }
  ],
  primaryNav: [
    {
      title: 'KPIs',
      id: '1',
      url: '#kpis'
    },
    {
      title: 'HR Teaser',
      id: '2',
      url: '#hrteaser'
    },
    {
      title: 'Rotating Carousel',
      id: '3',
      url: '#carousel'
    },
    {
      title: 'Locations',
      id: '4',
      url: '#locations',
      subNav: [
        { id: '1', title: 'WIKA Gruppe', url: 'http://www.google.com' },
        { id: '2', title: 'WIKA Deutschland', url: '#' },
        { id: '3', title: 'Neuheiten & Messen', url: '#' }
      ]
    }
  ]
}
</script>

<style module lang="scss">
@import './MicrositeHR.scss';
</style>
